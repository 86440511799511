<template>
    <div :style="computedStyle" v-show="!isHidden">
  <span class="registry_fields_label xref_field_label">
    {{ label }}
    <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
      <i class="el-icon-question"></i>
    </el-tooltip>
  </span>
        <el-form-item
                :prop="name"
                :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
    ]">
            <treeselect
                    ref="treeselect"
                    :placeholder="placeholder || $locale.main.placeholder.select"
                    class="custom_scrollbar xref_field"
                    v-model="localValue"
                    :options="computedOptions"
                    :load-options="loadOptions"
                    :disabled="readonly"
                    :normalizer="normalizer"
                    :clear-on-select="true"
                    :clear-value-text="treeXrefField.clearValueText"
                    :no-children-text="treeXrefField.noChildrenText"
                    :loading-text="$locale.main.message.loading"
                    :no-options-text="treeXrefField.noOptionsText"
                    :no-results-text="treeXrefField.noResultsText"
                    :match-keys="treeXrefField.matchKeys"
                    :disable-branch-nodes="(!recursiveGroup || !!groupBy)"
                    :async="true"
                    :disable-immediate-search="true"
                    :cache-options="false"
                    :append-to-body="true"
                    :searchable="true"
                    :limit="3"
                    @open="onMenuOpen"
                    @search-change="onSearch"
            >
                <template slot="before-list">
                    <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
                        <div class="vue-treeselect__icon-container">
                            <span class="vue-treeselect__icon-warning"/>
                        </div>
                        <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
                {{ treeXrefField.pressEnterToSearchText }}
              </span>
                    </div>
                </template>
                <template slot="option-label"
                          slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }">
                    <label :title="node.label" :class="labelClassName" v-if="!node.raw.isLoadingLabel" style="color: #606266;">
                        {{ node.label }}
                        <span v-if="node.raw.children_count"
                              :class="countClassName">({{ node.raw.children_count }})</span>
                    </label>
                    <div v-else class="vue-treeselect__loading-tip">
                        <div class="vue-treeselect__icon-container">
                            <span class="vue-treeselect__icon-loader"></span>
                        </div>
                        <span class="vue-treeselect__tip-text vue-treeselect__loading-tip-text">
                {{ $locale.main.message.loading }}
              </span>
                    </div>
                </template>
            </treeselect>
        </el-form-item>
        <slot></slot>
    </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import Treeselect, { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import '@bingosoftnn/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: 'xref_field',
  mixins: [mixin, registryMixin],
  components: {
    Treeselect
  },
  props: {
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean
    },
    alias: {
      description: 'Выводить поля',
      type: String
    },
    stateId: {
      description: 'Состояние (id)',
      type: String
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    groupBy: {
      description: 'Группировка (attr_N_)',
      type: String
    },
    recursiveGroup: {
      description: 'Рекурсивная группировка',
      type: Boolean
    }
  },
  data () {
    return {
      treeXrefField: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noOptionsText: 'Нет данных',
        noResultsText: 'Не найдено',
        pressEnterToSearchText: 'Для поиска нажмите Enter'
      },
      isAllRecordLoaded: false,
      search: null,
      offset: 0,
      localValue: undefined,
      foundOptions: [],
      options: [],
      loaded: false,
      loading: false,
      searchLoading: false,
      searchLoaded: false
    }
  },
  async mounted () {
    if (this.isEditor()) {
      return false
    }
    this.parseValue()
  },
  watch: {
    value: {
      async handler (value) {
        if (value !== this.localValue) {
          await this.parseValue()
          this.$emit('input', this.localValue || null)
        }
      }
    },
    localValue: {
      handler () {
        this.$emit('input', this.localValue || null)
      }
    },
    dataFilters: {
      async handler () {
        if (this.loaded) {
          this.offset = 0
          this.options = await this.loadData(true)
        }
      }
    }
  },
  computed: {
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    },
    attributeId () {
      return parseInt(/attr_([0-9]+)_/i.exec(this.name) ? /attr_([0-9]+)_/i.exec(this.name)[1] : 0)
    },
    computedOptions () {
      if (this.loading) {
        return [...this.options, ...[{ id: -1, isDisabled: true, isLoadingLabel: true }]]
      } else if (this.searchLoaded) {
        return this.foundOptions
      } else {
        return this.options
      }
    },
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let type = `eq`
          if (item.isXref) {
            type = `eqx`
          }
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              filters.push(`${item.alias},${type},${this.getModel()[item.attribute] + '' || 0}`)
            }
          } else if (item.type === 'constant' && item.alias) {
            filters.push(`${item.alias},${type},${item.attribute}`)
          } else if (item.type === 'current_user') {
            filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
          }
        })
      }
      return filters
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  methods: {
    async parseValue () {
      if (!this.value) {
        this.localValue = null
        return false
      }
      let parsed = null
      try {
        parsed = JSON.parse(this.value)
      } catch (e) {
      }
      if (parsed instanceof Array && parsed.length > 0) {
        if (!this.options.find((item) => {
          return item.id === parsed[0].id
        })) {
          this.options = this.options.concat(parsed)
        }
        this.localValue = parsed[0].id
      } else if (typeof parsed === 'number') {
        if (!this.options.find((item) => {
          return item.id === parsed
        })) {
          this.options = await this.loadData(true, null, parsed)
          this.loaded = false
        }
        this.localValue = parsed
      } else {
        this.localValue = null
      }
    },
    normalizer (node) {
      return {
        id: node.id,
        label: this.getNodeLabel(node)
      }
    },
    getNodeLabel (node) {
      let name = (node.name || node.id)
      if (!this.alias) {
        return name
      }
      let label = this.alias
      label = label.replace(`{{name}}`, name)
      let attributes = this.alias.match(/\{{(.*?)\}}/g) || []
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        label = label.replace(`{{${attribute}}}`, node[attribute] || '')
      })

      return label
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        parentNode.children = await this.loadData(true, parentNode.id)
        callback()
      } else if (action === ASYNC_SEARCH) {
        this.searchLoaded = false
        this.searchLoading = true
        this.foundOptions = await this.loadData(true)
        callback(null, this.foundOptions)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    onSearch (value) {
      this.search = value
      this.searchLoaded = false
      this.offset = 0
      this.foundOptions = []
    },
    async onMenuOpen () {
      this.options = await this.loadData()
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu()
        menu.addEventListener('scroll', async () => {
          const hasReachedEnd = menu.scrollHeight - menu.scrollTop <= menu.clientHeight
          if (hasReachedEnd && !this.isAllRecordLoaded && !this.search && !this.groupBy && !this.loading) {
            this.offset += 100
            this.options = await this.loadData(true)
          }
        })
      })
    },
    getParameters (parentNode = null, valueId) {
      let answer = []
      if (this.search) {
        answer.push(`search=${encodeURIComponent(this.search)}`)
      } else {
        if (valueId) {
          answer.push(`filter[${this.dataFilters.length}]=id,eq,${valueId}`)
        } else {
          if (this.groupBy) {
            answer.push(`group_by=${this.groupBy}`)
            if (parentNode) {
              answer.push(`node=${parentNode}`)
            }
            if (this.recursiveGroup) {
              answer.push(`recursive=1`)
            }
          } else {
            answer.push(`offset=${this.offset}`)
            answer.push(`limit=100`)
          }
        }
      }
      if (this.stateId) {
        answer.push(`state_id=${this.stateId}`)
      }
      this.dataFilters.forEach((filter, index) => {
        answer.push(`filter[${index}]=${filter}`)
      })
      return answer
    },
    async loadData (force = false, parentNode = null, valueId = null) {
      if (!this.loaded || force) {
        this.loading = true
        if (this.attributeId) {
          let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.attributeId}/data?${this.getParameters(parentNode, valueId).join('&')}`)
            .catch(() => {
              this.loading = false
            })
          this.loading = false
          this.loaded = true
          if (this.offset > 0) {
            this.isAllRecordLoaded = response.data.length === 0
            return [...this.options, ...response.data]
          } else {
            if (this.groupBy) {
              return (response.data || []).map((item) => {
                item.children = (item.children_count > 0 || (!this.recursiveGroup && !parentNode && !this.search)) ? null : false
                return item
              })
            } else {
              return response.data
            }
          }
        }
      }
      return this.options
    }
  }
}
</script>

<style>
    .xref_field_label {
      position: relative;
      top: 13px;
    }
    .xref_field.vue-treeselect {
        display: inline-block;
        top: 16px;
    }
    .xref_field .vue-treeselect__control {
        height: 40px;
    }

    .xref_field .vue-treeselect__single-value {
        color: #606266;
    }

    .xref_field .vue-treeselect__input {
        color: #606266;
        vertical-align: baseline;
    }

    .xref_field .vue-treeselect__placeholder {
        line-height: 40px;
    }

    .xref_field .vue-treeselect__value-container {
        line-height: 20px;
    }
    .vue-treeselect__portal-target {
        z-index: 10000 !important;
    }
</style>
